import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { UserRegister } from "../core/_models";
import { EmployerRegisters } from "../core/_models";
import { AlertContext } from "./alert-context";
import { createEmployeerData } from "../core/_requests";
import PhoneInput from "react-phone-input-2";

const employerSchema = Yup.object().shape({
  company: Yup.string()

    .max(50, "Maximum 50 letters")
    .required("Company Name is required"),
  designation: Yup.string()

    .max(50, "Maximum 50 letters")
    .required("Designation Name is required"),
  firstName: Yup.string()
    .max(50, "Maximum 50 letters")
    .required("First Name is required"),
  lastName: Yup.string()
    .min(1, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Last Name is required"),
  mobile: Yup.string()
    .matches(/^\d+$/, "Mobile number should contain only numbers")
    .min(10, "Minimum 10 letters")
    .max(13, "Maximum 13 letters")
    .required("Mobile is required"),
  email: Yup.string()
    .email("Invalid email address")
    .min(3, "Minimum 3 letters")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one capital letter")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (!@#$%^&*)"
    )
    .max(50, "Maximum 50 letters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test(
      "passwords-match",
      "Both passwords should be the same",
      function (value) {
        return this.parent.password === value;
      }
    ),
});
//INITIAL VALUE
const initialValues = {
  company: "",
  designation: "",
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  address: "",
  password: "",
  confirmPassword: "",
  Code: "",
  Message: "",
  Data: "",
};

//FUNCTION START
const EmployerRegister = () => {
  const { showAlert } = useContext(AlertContext);
  const [activeTab, setActiveTab] = useState("employee");
  const [activeGenderIndex, setActiveGenderIndex] = useState(1);

  //GET GENDER TYPE HERE
  var getGender = (genderType: number) => {
    setActiveGenderIndex(genderType);
    formik.setFieldValue("gender", genderType);
  };
  //TO GET EMAIL FOR OTP PAGE
  const navigate = useNavigate();
  //SUBMIT START
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const formik = useFormik<EmployerRegisters>({
    initialValues,
    validationSchema: employerSchema,
    onSubmit: async (values, actions) => {
      //GET EMAIL
      sessionStorage.setItem("Eplremail", values.email);

      setLoading(true);
      setTimeout(() => {
        createEmployeerData(values).then((res) => {
          if (res?.Code === "1") {
            // actions.resetForm({
            //   values: initialValues,
            // });
            navigate("/employer-otp-verification");
          } else {
            showAlert(res?.Message, res?.Code);
          }
        });

        setLoading(false);
      }, 1000);
    },
  });
  const { show, message, type } = useContext(AlertContext);
  const [showPassword, setShowPassword] = useState(false);
  const [CshowPassword, setShowCPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleCTogglePassword = () => {
    setShowCPassword(!CshowPassword);
  };

  const [category, setcategory] = useState("Company");
  const handleButtonClick = (tab: any) => {
    setActiveTab(tab);
  };

  const handlecategory = (e: any) => {
    setcategory(e.target.value);
    formik.setFieldValue("category", e.target.value);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit} className="custom-form">
        <div>
          <div className="mb-4">
            <label className="form-label">
              Category<span className="astrick">*</span>
            </label>
            <div className="checkbox-wrap">
              <div className="form-check form-check-inline me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="Company"
                  checked={category === "Company"}
                  onChange={handlecategory}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Company
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  value="Non-Profit Organisation"
                  checked={category === "Non-Profit Organisation"}
                  onChange={handlecategory}
                />
                <label className="form-check-label" htmlFor="inlineRadio3">
                  Non-Profit Organisation
                </label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label className="form-label">
              {category === "Company" ? (
                <>
                  Company Name<span className="astrick">*</span>
                </>
              ) : (
                <>
                  Organisation Name<span className="astrick">*</span>
                </>
              )}
            </label>
            <input
              type="text"
              className={clsx(
                "form-control",
                {
                  "is-invalid": formik.touched.company && formik.errors.company,
                },
                {
                  "is-valid": formik.touched.company && !formik.errors.company,
                }
              )}
              {...formik.getFieldProps("company")}
            />
            {formik.touched.company && formik.errors.company && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.company}</span>
              </div>
            )}
          </div>
          <div className="mb-4">
            <div className="label-group d-flex">
              <label className="form-label w-50">
                First Name<span className="astrick">*</span>
              </label>
              <label className="form-label w-50">
                Last Name<span className="astrick">*</span>
              </label>
            </div>
            <div className="input-group">
              <input
                type="text"
                aria-label="First name"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.firstName && formik.errors.firstName,
                  },
                  {
                    "is-valid":
                      formik.touched.firstName && !formik.errors.firstName,
                  }
                )}
                {...formik.getFieldProps("firstName")}
              />
              <input
                type="text"
                aria-label="Last name"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.lastName && formik.errors.lastName,
                  },
                  {
                    "is-valid":
                      formik.touched.lastName && !formik.errors.lastName,
                  }
                )}
                {...formik.getFieldProps("lastName")}
              />
            </div>
            {(formik.touched.firstName || formik.touched.lastName) &&
              ((formik.errors.firstName && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.firstName}</span>
                </div>
              )) ||
                (formik.errors.lastName && (
                  <div className="fv-plugins-message-container">
                    <span role="alert">{formik.errors.lastName}</span>
                  </div>
                )))}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Gender<span className="astrick">*</span>
            </label>
            <div className="render-switch">
              <span
                className={activeGenderIndex === 1 ? "mb-0 active" : "mb-0"}
                onClick={() => getGender(1)}
              >
                Male
              </span>
              <span
                className={activeGenderIndex === 2 ? "mb-0 active" : "mb-0"}
                onClick={() => getGender(2)}
              >
                Female
              </span>
              <span
                className={activeGenderIndex === 3 ? "mb-0 active" : "mb-0"}
                onClick={() => getGender(3)}
              >
                Others
              </span>
            </div>
          </div>
          <div className="mb-4">
            <label className="form-label">
              Designation<span className="astrick">*</span>
            </label>
            <input
              type="text"
              className={clsx(
                "form-control",
                {
                  "is-invalid":
                    formik.touched.designation && formik.errors.designation,
                },
                {
                  "is-valid":
                    formik.touched.designation && !formik.errors.designation,
                }
              )}
              {...formik.getFieldProps("designation")}
            />
            {formik.touched.designation && formik.errors.designation && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.designation}</span>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="form-label">
              LinkedIn Profile<span className=""></span>
            </label>
            <div className="input-group with-ico">
              <input
                type="text"
                className="form-control"
                placeholder="Paste profile link here..."
              />
              <span className="input-group-text c-pointer">
                <img
                  src="../../../media/images/linkedin-ico.svg"
                  alt="LinkedInProfile"
                />
              </span>
            </div>
          </div>
          <div className="mb-4">
            <label className="form-label">
              Contact Number<span className="astrick">*</span>
            </label>
            <PhoneInput
              copyNumbersOnly={true}
              inputClass="form-control"
              country={"in"}
              enableSearch={true}
              value={formik.values.mobile}
              onChange={(phone) => {
                formik.setFieldValue("mobile", phone);
                formik.setFieldError("mobile", formik.errors.mobile);
              }}
              onBlur={formik.handleBlur("mobile")}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.mobile}</span>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Email address<span className="astrick">*</span>
            </label>
            <input
              type="email"
              className={clsx(
                "form-control",
                {
                  "is-invalid": formik.touched.email && formik.errors.email,
                },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.email}</span>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Password<span className="astrick">*</span>
            </label>
            <div className="input-group with-ico">
              <input
                type={showPassword ? "text" : "password"}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid":
                      formik.touched.password && !formik.errors.password,
                  }
                )}
                {...formik.getFieldProps("password")}
              />
              <span
                className="input-group-text c-pointer"
                onClick={handleTogglePassword}
              >
                <span className="material-symbols-outlined">
                  {" "}
                  {showPassword ? "visibility" : "visibility_off"}
                </span>
              </span>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.password}</span>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Confirm Password<span className="astrick">*</span>
            </label>
            <div className="input-group with-ico">
              <input
                type={CshowPassword ? "text" : "password"}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword,
                  },
                  {
                    "is-valid":
                      formik.touched.confirmPassword &&
                      !formik.errors.confirmPassword,
                  }
                )}
                {...formik.getFieldProps("confirmPassword")}
              />
              <span
                className="input-group-text c-pointer"
                onClick={handleCTogglePassword}
              >
                <span className="material-symbols-outlined">
                  {CshowPassword ? "visibility" : "visibility_off"}
                </span>
              </span>
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.confirmPassword}</span>
                </div>
              )}
          </div>
          <div className="mb-5">
            <div className="form-check form-check-inline tc">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                checked={agreed}
                onChange={() => setAgreed(!agreed)}
              />
              <label className="form-check-label" htmlFor="inlineCheckbox1">
                I Agree to all <Link to="#">Terms &amp; Conditions</Link>
              </label>
            </div>
          </div>

          <div className="mb-4 text-center">
            <button
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid || !agreed}
              className="btn btn-asphalt min-w mb-3"
            >
              {!loading && <span className="indicator-label">Signup</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <p className="mb-0">
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </form>
      {/* <!-- Main Wrapper Ends here --> */}
    </>
  );
};

export { EmployerRegister };
