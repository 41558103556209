import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../core/Auth";
import { getUserByToken, login } from "../core/_requests";
import clsx from "clsx";
import { EmployeeLogin } from "./EmployeeLogin";
import { EmployerLogin } from "./EmployerLogin";

//FUNCTION START
const Login = () => {
  //SUBMIT LOGIN START
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [showAlert, setShowAlert] = useState(true);

  const handleClose = () => {
    setShowAlert(false);
  };

  const [activeTab, setActiveTab] = useState("employee");

  const handleButtonClick = (tab: any) => {
    setActiveTab(tab);
  };
  if (activeTab === "employee") {
    var userType = "1";
  } else {
    var userType = "2";
  }
  sessionStorage.setItem("userType", userType);

  return (
    <>
      {/* <!-- Main Wrapper Starts here --> */}
      <section className="container-fluid">
        <div className="row">
          {/* <!-- Left Wrap Starts here --> */}
          <div className="col-md-6 left-wrap">
            <div className="top">
              <div className="nav-brand">
                <img
                  className="img-fluid"
                  src={"../../../media/images/logo-white.png"}
                  alt="Forte"
                />
              </div>
              <h1 className="mb-3 mb-md-4">Welcome to Forte Login!</h1>
              <p>
                You can easily manage your account and enjoy a seamless user
                experience.
              </p>
            </div>
            <img
              className="img-fluid btm-img d-none d-md-inline-block"
              src="../../../media/images/signup-btm-img.svg"
              alt="Signup"
            />
          </div>
          {/* <!-- Left Wrap Ends here --> */}
          {/* <!-- Right Wrap Starts here --> */}
          <div className="col-6 right-wrap">
            <div className="form-wrap">
              <h2 className="mb-md-5 mb-4">Hi, Welcome back!</h2>
              <div className="row mb-4">
                <div className="col-12 mb-5">
                  <h5>You’re a</h5>
                  <div className="role">
                    <p
                      className={`mb-0 ${
                        activeTab === "employee" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick("employee")}
                    >
                      <span className="material-symbols-outlined">
                        account_box
                      </span>
                      Employee
                    </p>
                    <p
                      className={`mb-0 ${
                        activeTab === "employer" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick("employer")}
                    >
                      <span className="material-symbols-outlined">
                        person_search
                      </span>
                      Employer
                    </p>
                  </div>
                </div>
              </div>
              {activeTab === "employee" ? <EmployeeLogin /> : <EmployerLogin />}
            </div>
          </div>
          {/* <!-- Right Wrap Starts here --> */}
        </div>
      </section>
      {/* <!-- Main Wrapper Ends here --> */}
    </>
  );
};

export { Login };
