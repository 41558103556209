import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { UserRegister } from "../core/_models";
import { userRegistration } from "../core/_requests";
import { AlertContext } from "./alert-context";
import { AlertBox } from "./alert-message";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
//LOGIN SCHEMA

const registerSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("First Name is required"),
  lastName: Yup.string()
    .min(1, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Last Name is required"),
  mobile: Yup.string()
    .matches(/^[-+]?[\d]+$/, "Mobile number should contain only numbers")
    .min(10, "Minimum 10 numbers")
    .required("Mobile is required"),
  email: Yup.string()
    .email("Invalid email address")
    .min(3, "Minimum 3 letters")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one capital letter")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (!@#$%^&*)"
    )
    .max(50, "Maximum 50 letters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test(
      "passwords-match",
      "Both passwords should be the same",
      function (value) {
        return this.parent.password === value;
      }
    ),
});

//INITIAL VALUE
const initialValues = {
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  password: "",
  confirmPassword: "",
  Code: "",
  Message: "",
  Data: "",
  country: "",
};

//FUNCTION START
const EmployeeRegister = () => {
  const { showAlert } = useContext(AlertContext);

  //TO GET EMAIL FOR OTP PAGE
  const navigate = useNavigate();
  //SUBMIT START
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const formik = useFormik<UserRegister>({
    initialValues,
    validationSchema: registerSchema,
    onSubmit: async (values, actions) => {
      //GET EMAIL
      sessionStorage.setItem("email", values.email);

      setLoading(true);
      setTimeout(() => {
        userRegistration(values).then((res) => {
          if (res?.Code === "1") {
            // actions.resetForm({
            //   values: initialValues,
            // });
            navigate("/otp-verification");
          } else {
            showAlert(res?.Message, res?.Code);
          }
        });

        setLoading(false);
      }, 1000);
    },
  });
  const { show, message, type } = useContext(AlertContext);
  const [showPassword, setShowPassword] = useState(false);
  const [CshowPassword, setShowCPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleCTogglePassword = () => {
    setShowCPassword(!CshowPassword);
  };

  const [category, setcategory] = useState("Company");
  const [activeTab, setActiveTab] = useState("employee");
  const handleButtonClick = (tab: any) => {
    setActiveTab(tab);
  };

  const handlecategory = (e: any) => {
    setcategory(e.target.value);
  };

  return (
    <>
      {/* <!-- Main Wrapper Starts here --> */}
      <form onSubmit={formik.handleSubmit} className="custom-form">
        <div>
          <div className="mb-4">
            <div className="label-group d-flex">
              <label className="form-label w-50">
                First Name<span className="astrick">*</span>
              </label>
              <label className="form-label w-50">
                Last Name<span className="astrick">*</span>
              </label>
            </div>
            <div className="input-group">
              <input
                type="text"
                aria-label="First name"
                {...formik.getFieldProps("firstName")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.firstName && formik.errors.firstName,
                  },
                  {
                    "is-valid":
                      formik.touched.firstName && !formik.errors.firstName,
                  }
                )}
              />

              <input
                type="text"
                aria-label="Last name"
                {...formik.getFieldProps("lastName")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.lastName && formik.errors.lastName,
                  },
                  {
                    "is-valid":
                      formik.touched.lastName && !formik.errors.lastName,
                  }
                )}
              />
            </div>
            {(formik.touched.firstName || formik.touched.lastName) &&
              ((formik.errors.firstName && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.firstName}</span>
                </div>
              )) ||
                (formik.errors.lastName && (
                  <div className="fv-plugins-message-container">
                    <span role="alert">{formik.errors.lastName}</span>
                  </div>
                )))}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Contact Number<span className="astrick">*</span>
            </label>
            <PhoneInput
              copyNumbersOnly={true}
              inputClass="form-control"
              country={"in"}
              enableSearch={true}
              value={formik.values.mobile}
              onChange={(phone) => {
                formik.setFieldValue("mobile", phone);
                formik.setFieldError("mobile", formik.errors.mobile);
              }}
              // onChange={(phone) => {
              //   const countryCode = formik.values.country; // Get the selected country code
              //   const phoneNumber = phone.replace(`+${countryCode}`, ""); // Remove the country code from the phone number
              //   const fullPhoneNumber = `+${countryCode}${phoneNumber}`; // Add the country code to the phone number

              //   formik.setFieldValue("mobile", fullPhoneNumber);
              // }}
              onBlur={formik.handleBlur("mobile")}
            />
            {/* <input
              type="text"
              {...formik.getFieldProps("mobile")}
              className={clsx(
                "form-control",
                {
                  "is-invalid": formik.touched.mobile && formik.errors.mobile,
                },
                {
                  "is-valid": formik.touched.mobile && !formik.errors.mobile,
                }
              )}
            /> */}
            {formik.touched.mobile && formik.errors.mobile && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.mobile}</span>
              </div>
            )}
            {/* <p className="error-msg">Please fill this field</p> */}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Email address<span className="astrick">*</span>
            </label>
            <input
              type="email"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control",
                {
                  "is-invalid": formik.touched.email && formik.errors.email,
                },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.email}</span>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Password<span className="astrick">*</span>
            </label>
            <div className="input-group with-ico">
              <input
                type={showPassword ? "text" : "password"}
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid":
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />

              <span
                className="input-group-text c-pointer"
                onClick={handleTogglePassword}
              >
                <span className="material-symbols-outlined">
                  {showPassword ? "visibility_off" : "visibility"}
                </span>
              </span>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.password}</span>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Confirm Password<span className="astrick">*</span>
            </label>
            <div className="input-group with-ico">
              <input
                type={CshowPassword ? "text" : "password"}
                {...formik.getFieldProps("confirmPassword")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword,
                  },
                  {
                    "is-valid":
                      formik.touched.confirmPassword &&
                      !formik.errors.confirmPassword,
                  }
                )}
              />

              <span
                className="input-group-text c-pointer"
                onClick={handleCTogglePassword}
              >
                <span className="material-symbols-outlined">
                  {CshowPassword ? "visibility_off" : "visibility"}
                </span>
              </span>
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.confirmPassword}</span>
                </div>
              )}
          </div>
          <div className="mb-5">
            <div className="form-check form-check-inline tc">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                checked={agreed}
                onChange={() => setAgreed(!agreed)}
              />
              <label className="form-check-label" htmlFor="inlineCheckbox1">
                I Agree to all <Link to="#">Terms &amp; Conditions</Link>
              </label>
            </div>
          </div>
        </div>

        <div className="mb-4 text-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid || !agreed}
            className="btn btn-asphalt min-w mb-3"
          >
            {!loading && <span className="indicator-label">Signup</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <p className="mb-0">
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </div>
      </form>

      {/* <!-- Main Wrapper Ends here --> */}
    </>
  );
};

export { EmployeeRegister };
