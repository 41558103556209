import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { SplashScreen } from "../features/SplashScreen";
import { useAuth } from "../auth";
import { EmployerDashboard } from "../modules/employer/EmployerDashboard";
import ViewProfileInfo from "../modules/employer/ViewProfileInfo";
import { StepStatusProvider } from "../modules/profile/profile-form/StepStatusContext";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();

  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));

  var Gotoo = "";
  if (Number(currentUser?.profileStatus) == 8) {
    var Gotoo = "/profile/view";
  }
  if (Number(currentUser?.profileStatus) == 0) {
    var Gotoo = "/profile/add/personal-detail";
  }
  if (Number(currentUser?.profileStatus) == 1) {
    var Gotoo = "/profile/add/experience";
  }
  if (Number(currentUser?.profileStatus) == 2) {
    var Gotoo = "/profile/add/visa";
  }
  if (Number(currentUser?.profileStatus) == 3) {
    var Gotoo = "/profile/add/skills";
  }
  if (Number(currentUser?.profileStatus) == 4) {
    var Gotoo = "/profile/add/education";
  }
  if (Number(currentUser?.profileStatus) == 5) {
    var Gotoo = "/profile/add/expectation";
  }
  if (Number(currentUser?.profileStatus) == 6) {
    var Gotoo = "/profile/add/social-media";
  }
  if (Number(currentUser?.profileStatus) == 7) {
    var Gotoo = "/profile/add/photo";
  }
  if (Number(currentUser?.profileStatus) == 9) {
    var Gotoo = "/dashboard";
  }

  return (
    <Routes>
      <Route path="auth/*" element={<Navigate to={Gotoo} />} />

      <Route
        path="profile/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <StepStatusProvider>
              <ProfilePage />
            </StepStatusProvider>
          </Suspense>
        }
      />
      <Route
        path="dashboard/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <EmployerDashboard />
          </Suspense>
        }
      />
      <Route
        path="employee-profile/:id"
        element={
          <Suspense fallback={<SplashScreen />}>
            <ViewProfileInfo />
          </Suspense>
        }
      />
    </Routes>
  );
};

export { PrivateRoutes };
