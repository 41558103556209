import { getToken } from "../../../../auth";

import axios from "axios";
import { ExperienceDetail, PersonalDetail } from "./model";
//BASE_URL
const API_URL = process.env.REACT_APP_SKYX_API_URL;
//API URL
export const ADDRESS_URL = `${API_URL}/profile`;
export const PERSONAL_DETAIL_URL = `${API_URL}/profile/personalDetails`;
export const EXPERIENCE_DETAIL_URL = `${API_URL}/profile/workExperience`;

//PROFILE PHOTO
export function updatePhoto(data: FormData) {
  return axios.post(
    `${API_URL}/profile/profilePictureDetails`,

    data,

    getToken()
  );
}
//PROFILE PHOTO
export function updateSocialMedia(data: any) {
  return axios.post(
    `${API_URL}/profile/socialMediaDetails`,

    data,

    getToken()
  );
}
//Expectation
export function updateExpectation(data: any) {
  return axios.post(
    `${API_URL}/profile/expectationDetails`,

    data,

    getToken()
  );
}
//Education
export function updateEducation(data: any) {
  return axios.post(
    `${API_URL}/profile/educationDetails`,

    data,

    getToken()
  );
}

//GET COUNTRIES
const getCountries = async () => {
  const res = await axios.get(`${ADDRESS_URL}/countries`, getToken());
  return res;
};
//GET STATES
const getStates = async (countryId: any) => {
  const res = await axios.get(`${ADDRESS_URL}/states/${countryId}`, getToken());
  return res;
};
//GET CITIES
const getCities = async (stateId: any) => {
  const res = await axios.get(`${ADDRESS_URL}/cities/${stateId}`, getToken());
  return res;
};

//CREATE PERSONAL DETAIL
const personalDetail = (
  data: PersonalDetail
): Promise<PersonalDetail | undefined> => {
  return axios.post(PERSONAL_DETAIL_URL, data, getToken()).then((response) => {
    return response.data;
  });
};

//CREATE EXPERIENCE DETAIL
const experienceDetail = (data: FormData) => {
  return axios
    .post(EXPERIENCE_DETAIL_URL, data, getToken())
    .then((response) => {
      return response.data;
    });
};

//skill
export function updateSkill(data: any) {
  return axios.post(`${API_URL}/profile/skillDetails`, data, getToken());
}
export function updateVisa(data: any) {
  return axios.post(`${API_URL}/profile/visaDetails`, data, getToken());
}
export function getUserList(sort: any) {
  return axios.post(`${API_URL}/profile/listUserProfile`, { sort }, getToken());
}
//PURSUE EMPLOYMENT
const pursueEmployment = async (userId: any, pursueValue: any) => {
  const res = await axios.post(
    `${API_URL}/profile/pursueEmployment/${userId}/${pursueValue}`,
    "",
    getToken()
  );

  return res;
};

//GET COUNTRIES
const getLanguages = async () => {
  const res = await axios.get(`${ADDRESS_URL}/languages`, getToken());
  return res;
};

const editPersonalDetail = (
  data: PersonalDetail
): Promise<PersonalDetail | undefined> => {
  return axios
    .post(`${API_URL}/profile/editPersonalDetails`, data, getToken())
    .then((response) => {
      return response.data;
    });
};

//CREATE EXPERIENCE DETAIL
const editExperienceDetail = (data: FormData) => {
  return axios
    .post(`${API_URL}/profile/editWorkExperience`, data, getToken())
    .then((response) => {
      return response.data;
    });
};

export function editVisa(data: any) {
  return axios.post(`${API_URL}/profile/editVisaDetails`, data, getToken());
}

//Skill
export function editSkill(data: any) {
  return axios.post(`${API_URL}/profile/editSkillDetails`, data, getToken());
}

//Edit Education
export function editEducation(data: any) {
  return axios.post(
    `${API_URL}/profile/editEducationDetails`,
    data,
    getToken()
  );
}
//Edit Expectation
export function editExpectation(data: any) {
  return axios.post(
    `${API_URL}/profile/editExpectationDetails`,
    data,
    getToken()
  );
}
//PROFILE PHOTO
export function editProfileDetail(data: FormData) {
  return axios.post(
    `${API_URL}/profile/editProfilePictureDetails`,

    data,

    getToken()
  );
}
//PROFILE PHOTO
export function editSocialMedia(data: any) {
  return axios.post(
    `${API_URL}/profile/editSocialMediaDetails`,

    data,

    getToken()
  );
}
export {
  getCountries,
  getStates,
  getCities,
  personalDetail,
  experienceDetail,
  pursueEmployment,
  getLanguages,
  editPersonalDetail,
  editExperienceDetail,
};
