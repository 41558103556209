import React, { ReactNode, createContext, useState } from "react";

interface StepStatus {
  personalDetail: boolean;
  experience: boolean;
  visa: boolean;
  skills: boolean;
  education: boolean;
  expectation: boolean;
  socialMedia: boolean;
  photo: boolean;
}

interface StepStatusContextProps {
  stepStatus: StepStatus;
  updateStepStatus: (newStepStatus: StepStatus) => void;
}

const StepStatusContext = createContext<StepStatusContextProps>({
  stepStatus: {
    personalDetail: false,
    experience: false,
    visa: false,
    skills: false,
    education: false,
    expectation: false,
    socialMedia: false,
    photo: false,
  },
  updateStepStatus: () => {},
});
interface StepStatusProviderProps {
  children: ReactNode;
}
const StepStatusProvider: React.FC<StepStatusProviderProps> = ({
  children,
}) => {
  const [stepStatus, setStepStatus] = useState<StepStatus>({
    personalDetail: true,
    experience: false,
    visa: false,
    skills: false,
    education: false,
    expectation: false,
    socialMedia: false,
    photo: false,
  });

  const updateStepStatus = (newStepStatus: StepStatus) => {
    localStorage.setItem("formData", JSON.stringify(newStepStatus));
    setStepStatus(newStepStatus);
  };

  return (
    <StepStatusContext.Provider value={{ stepStatus, updateStepStatus }}>
      {children}
    </StepStatusContext.Provider>
  );
};

export { StepStatusContext, StepStatusProvider };
