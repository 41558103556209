import { AuthModel, UserModel, UserRegister } from "./_models";
import axios from "axios";
import { getToken } from "../../auth/core/AuthHelpers";

//BASE_URL
const API_URL = process.env.REACT_APP_SKYX_API_URL;

//API URL
export const LOGIN = `${API_URL}/login/userLogin`;
export const VERIFY_EMAIL = `${API_URL}/login/verifyEmail`;
export const VERIFY_TOKEN = `${API_URL}/login/userVerifyToken`;
export const REGISTER_USER = `${API_URL}/login/createUserData`;
export const RESEND_OTP = `${API_URL}/login/resendOTP`;

//USER LOGIN
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN, {
    username,
    password,
  });
}
export function employerLogin(username: string, password: string) {
  return axios.post<AuthModel>(`${API_URL}/login/employerLogin`, {
    username,
    password,
  });
}
//VERIFY EMAIL
export function verifyEmail(email: string, emailOtp: string) {
  return axios.post(VERIFY_EMAIL, {
    email,
    emailOtp,
  });
}
//VERIFY EMAIL
export function EmployerverifyEmail(email: string, emailOtp: string) {
  return axios.post(`${API_URL}/login/employerVerifyMailOTP`, {
    email,
    emailOtp,
  });
}
//VERIFY EMAIL
export function employerresendOtp(email: any) {
  return axios.get(`${API_URL}/login/employerResendOTP/${email}`, getToken());
}
//VERIFY TOKEN
export function getUserByToken(token: any) {
  return axios.post<UserModel>(VERIFY_TOKEN, {
    token: token,
  });
}
//VERIFY TOKEN
export function employerUserVerifyToken(token: any) {
  return axios.post<UserModel>(`${API_URL}/login/employerUserVerifyToken`, {
    token: token,
  });
}
//REGISTER USER
const userRegistration = (
  data: UserRegister
): Promise<UserRegister | undefined> => {
  return axios.post(REGISTER_USER, data, getToken()).then((response) => {
    return response.data;
  });
};
//REGISTER Employeer
const createEmployeerData = (data: any) => {
  return axios
    .post(`${API_URL}/login/createEmployeerData`, data, getToken())
    .then((response) => {
      return response.data;
    });
};

//VERIFY Email for Forget Password
const sendOTPForForgetPassword = (data: any) => {
  console.log(data);
  return axios
    .post(`${API_URL}/login/forgetPassword`, data, getToken())
    .then((response) => {
      return response.data;
    });
};
//VERIFY OTP for Forget Password
const verifyOTPForForgetPassword = (data: any) => {
  console.log("vvvdata", data);
  return axios
    .post(`${API_URL}/login/checkOTP`, data, getToken())
    .then((response) => {
      return response.data;
    });
};
//RESEND OTP for Forget Password
const resendOTPForForgetPassword = (data: any) => {
  console.log(data);
  return axios
    .post(`${API_URL}/login/resendOTP`, data, getToken())
    .then((response) => {
      return response.data;
    });
};
//RESET OTP for Forget Password
const resetPassword = (data: any) => {
  console.log(data);
  return axios
    .post(`${API_URL}/login/resetPassword`, data, getToken())
    .then((response) => {
      return response.data;
    });
};
//VIEW USER
export function getUserProfile(id: any) {
  return axios.get(`${API_URL}/profile/viewProfile/${id}`, getToken());
}
export function resendOtp(email: any) {
  return axios.post(`${RESEND_OTP}/${email}`, getToken());
}
export {
  userRegistration,
  createEmployeerData,
  sendOTPForForgetPassword,
  verifyOTPForForgetPassword,
  resendOTPForForgetPassword,
  resetPassword,
};
