import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../core/Auth";
import { resetPassword } from "../core/_requests";
import clsx from "clsx";
import { AlertContext } from "./alert-context";

//LOGIN SCHEMA
const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one capital letter")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (!@#$%^&*)"
    )
    .max(50, "Maximum 50 letters")
    .required("Password is required"),
});
//INITIAL VALUE
const initialValues = {
  password: "",
};

//FUNCTION START
const ResetNewPassword = () => {
  // Retrieve the email value from sessionStorage
  var userType = sessionStorage.getItem("userType");
  var email = sessionStorage.getItem("email");

  //SUBMIT LOGIN START
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [close, setClose] = useState(true);
  const navigate = useNavigate();
  const { showAlert } = useContext(AlertContext);
  const [showPassword, setShowPassword] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClose = () => {
    setClose(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const data = { userType, email, newPassword: values.password };
      resetPassword(data).then((res) => {
        setIsFormSubmitted(true);
        //sessionStorage.removeItem("email");

        if (res?.Code === "1") {
          showAlert(res?.Message, res?.Code);
          navigate("/reset-success", { replace: true });
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("userType");
        } else {
          showAlert(res?.Message, res?.Code);
          navigate("/forget-password", { replace: true });
        }
      });
    },
  });
  const [activeTab, setActiveTab] = useState("employee");

  const handleButtonClick = (tab: any) => {
    setActiveTab(tab);
  };
  return (
    <>
      <section className="container-fluid">
        <div className="row">
          {/* <!-- Left Wrap Starts here --> */}
          <div className="col-md-6 left-wrap">
            <div className="top">
              <div className="nav-brand">
                <img
                  className="img-fluid"
                  src={"../../../media/images/logo-white.png"}
                  alt="Forte"
                />
              </div>
              <h1 className="mb-3 mb-md-4">Welcome to Forte Login!</h1>
              <p>
                You can easily manage your account and enjoy a seamless user
                experience.
              </p>
            </div>
            <img
              className="img-fluid btm-img d-none d-md-inline-block"
              src="../../../media/images/signup-btm-img.svg"
              alt="Signup"
            />
          </div>
          {/* <!-- Left Wrap Ends here --> */}
          {/* <!-- Right Wrap Starts here --> */}
          <div className="col-6 right-wrap">
            <div className="form-wrap">
              <h3 className="mb-md-5 mb-4">
                Lost your key? Unlock with a new password!
              </h3>
              <div className="row mb-4">
                <div className="col-12 mb-5">
                  <p>
                    1. Provide the email address associated with your account,
                    Submit the form.
                  </p>
                  <p>
                    2. Check your email for the OTP, Copy the OTP, Verify the
                    OTP.
                  </p>
                  <p>
                    3. Return to the password reset page, Paste or enter the
                    OTP.
                  </p>
                  <p>
                    4. Once the OTP is successfully verified, you will be
                    redirected to a new page where you can enter your new
                    password.
                  </p>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit} className="custom-form">
                {formik.status && close ? (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                      <button
                        onClick={handleClose}
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                ) : null}
                <div className="mb-4">
                  <label className="form-label">New Password</label>
                  <input
                    type="password"
                    {...formik.getFieldProps("password")}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.password && formik.errors.password,
                      },
                      {
                        "is-valid":
                          formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  )}
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-asphalt min-w mb-4 mt-2"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className="indicator-label">Submit</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                  <p className="mb-0">
                    Don’t have an account?{" "}
                    <Link to="/signUp">Create an Account </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- Right Wrap Starts here --> */}
        </div>
      </section>
    </>
  );
};

export { ResetNewPassword };
